const logo = `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1800 1566.68" style="enable-background:new 0 0 1800 1566.68;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#5185A8;}
	.st1{fill:#8EB0BB;}
	.st2{fill:#889FB4;}
	.st3{fill:#EAC064;}
</style>
<g>
	<g>
		<g>
			<g>
				<path class="st0" d="M767.59,1171.56c4.89-2.2,10.7-3.94,17.42-5.23c6.72-1.28,13.75-1.92,21.09-1.92
					c6.35,0,11.67,0.76,15.95,2.29c4.28,1.53,7.67,3.7,10.18,6.51c2.5,2.81,4.28,6.17,5.32,10.09c1.04,3.91,1.56,8.31,1.56,13.2
					c0,5.38-0.18,10.79-0.55,16.23c-0.37,5.44-0.58,10.79-0.64,16.04c-0.06,5.26,0.09,10.36,0.46,15.31
					c0.37,4.95,1.28,9.63,2.75,14.03h-19.44l-3.85-12.65h-0.92c-2.45,3.79-5.84,7.06-10.18,9.81c-4.34,2.75-9.93,4.13-16.78,4.13
					c-4.28,0-8.13-0.64-11.55-1.92c-3.42-1.28-6.36-3.12-8.8-5.5c-2.45-2.38-4.34-5.19-5.68-8.44c-1.35-3.24-2.02-6.88-2.02-10.91
					c0-5.62,1.25-10.36,3.76-14.21c2.5-3.85,6.11-6.97,10.82-9.35c4.7-2.38,10.33-4.03,16.87-4.95c6.54-0.92,13.84-1.19,21.91-0.83
					c0.85-6.84,0.37-11.76-1.47-14.76c-1.83-2.99-5.93-4.49-12.29-4.49c-4.77,0-9.81,0.49-15.13,1.47
					c-5.32,0.98-9.69,2.26-13.11,3.85L767.59,1171.56z M797.84,1239.77c4.77,0,8.55-1.07,11.37-3.21c2.81-2.14,4.89-4.43,6.23-6.88
					v-11.92c-3.79-0.37-7.43-0.43-10.91-0.18c-3.48,0.25-6.57,0.8-9.26,1.65c-2.69,0.86-4.83,2.08-6.42,3.67
					c-1.59,1.59-2.38,3.61-2.38,6.05c0,3.42,1.01,6.08,3.03,7.98C791.52,1238.83,794.3,1239.77,797.84,1239.77z"/>
				<path class="st0" d="M909.69,1188.62c-3.79-1.34-7.21-2.02-10.27-2.02c-4.28,0-7.86,1.13-10.73,3.39
					c-2.87,2.26-4.8,5.17-5.78,8.71v59.22h-23.84v-91.68h18.52l2.75,12.1h0.73c2.08-4.52,4.89-8,8.44-10.45
					c3.54-2.44,7.7-3.67,12.47-3.67c3.18,0,6.78,0.67,10.82,2.02L909.69,1188.62z"/>
				<path class="st0" d="M922.89,1171.56c4.89-2.2,10.7-3.94,17.42-5.23c6.72-1.28,13.75-1.92,21.09-1.92
					c6.35,0,11.67,0.76,15.95,2.29c4.28,1.53,7.67,3.7,10.18,6.51c2.5,2.81,4.28,6.17,5.32,10.09c1.04,3.91,1.56,8.31,1.56,13.2
					c0,5.38-0.18,10.79-0.55,16.23c-0.37,5.44-0.58,10.79-0.64,16.04c-0.06,5.26,0.09,10.36,0.46,15.31
					c0.37,4.95,1.28,9.63,2.75,14.03h-19.44l-3.85-12.65h-0.92c-2.45,3.79-5.84,7.06-10.18,9.81c-4.34,2.75-9.93,4.13-16.78,4.13
					c-4.28,0-8.13-0.64-11.55-1.92c-3.42-1.28-6.36-3.12-8.8-5.5c-2.45-2.38-4.34-5.19-5.68-8.44c-1.35-3.24-2.02-6.88-2.02-10.91
					c0-5.62,1.25-10.36,3.76-14.21c2.5-3.85,6.11-6.97,10.82-9.35c4.7-2.38,10.33-4.03,16.87-4.95c6.54-0.92,13.84-1.19,21.91-0.83
					c0.85-6.84,0.37-11.76-1.47-14.76c-1.83-2.99-5.93-4.49-12.29-4.49c-4.77,0-9.81,0.49-15.13,1.47
					c-5.32,0.98-9.69,2.26-13.11,3.85L922.89,1171.56z M953.15,1239.77c4.77,0,8.55-1.07,11.37-3.21c2.81-2.14,4.89-4.43,6.23-6.88
					v-11.92c-3.79-0.37-7.43-0.43-10.91-0.18c-3.48,0.25-6.57,0.8-9.26,1.65c-2.69,0.86-4.83,2.08-6.42,3.67
					c-1.59,1.59-2.38,3.61-2.38,6.05c0,3.42,1.01,6.08,3.03,7.98C946.82,1238.83,949.6,1239.77,953.15,1239.77z"/>
				<path class="st0" d="M1064.99,1188.62c-3.79-1.34-7.21-2.02-10.27-2.02c-4.28,0-7.86,1.13-10.73,3.39
					c-2.87,2.26-4.8,5.17-5.78,8.71v59.22h-23.84v-91.68h18.52l2.75,12.1h0.73c2.08-4.52,4.89-8,8.44-10.45
					c3.54-2.44,7.7-3.67,12.47-3.67c3.18,0,6.78,0.67,10.82,2.02L1064.99,1188.62z"/>
				<path class="st0" d="M1101.66,1166.25v52.07c0,7.46,0.89,12.84,2.66,16.14c1.77,3.3,5.23,4.95,10.36,4.95
					c4.52,0,8.28-1.34,11.28-4.03c2.99-2.69,5.23-5.99,6.69-9.9v-59.23h23.84v63.81c0,5.01,0.24,9.93,0.73,14.76
					c0.49,4.83,1.22,9.2,2.2,13.11h-17.97l-4.22-13.57h-0.73c-2.81,4.65-6.72,8.5-11.74,11.55c-5.01,3.05-10.94,4.58-17.79,4.58
					c-4.65,0-8.8-0.61-12.47-1.83c-3.67-1.22-6.72-3.24-9.17-6.05c-2.45-2.81-4.31-6.6-5.59-11.37c-1.28-4.77-1.93-10.76-1.93-17.97
					v-57.02H1101.66z"/>
				<path class="st0" d="M1233.5,1257.93v-52.07c0-7.45-1.07-12.84-3.21-16.14c-2.14-3.3-5.78-4.95-10.91-4.95
					c-4.52,0-8.34,1.31-11.46,3.94c-3.12,2.63-5.35,5.9-6.69,9.81v59.41h-23.84v-91.68h18.89l2.75,12.1h0.73
					c2.81-3.91,6.54-7.33,11.18-10.27c4.64-2.93,10.64-4.4,17.97-4.4c4.52,0,8.55,0.61,12.1,1.83c3.54,1.22,6.54,3.24,8.98,6.05
					c2.44,2.81,4.28,6.63,5.5,11.46c1.22,4.83,1.83,10.79,1.83,17.88v57.02H1233.5z"/>
				<path class="st0" d="M1276.77,1171.56c4.89-2.2,10.7-3.94,17.42-5.23c6.72-1.28,13.75-1.92,21.09-1.92
					c6.35,0,11.67,0.76,15.95,2.29c4.28,1.53,7.67,3.7,10.18,6.51c2.5,2.81,4.28,6.17,5.32,10.09c1.04,3.91,1.56,8.31,1.56,13.2
					c0,5.38-0.18,10.79-0.55,16.23c-0.37,5.44-0.58,10.79-0.64,16.04c-0.06,5.26,0.09,10.36,0.46,15.31
					c0.37,4.95,1.28,9.63,2.75,14.03h-19.44l-3.85-12.65h-0.92c-2.45,3.79-5.84,7.06-10.18,9.81c-4.34,2.75-9.93,4.13-16.78,4.13
					c-4.28,0-8.13-0.64-11.55-1.92c-3.42-1.28-6.36-3.12-8.8-5.5c-2.45-2.38-4.34-5.19-5.68-8.44c-1.35-3.24-2.02-6.88-2.02-10.91
					c0-5.62,1.25-10.36,3.76-14.21c2.5-3.85,6.11-6.97,10.82-9.35c4.7-2.38,10.33-4.03,16.87-4.95c6.54-0.92,13.84-1.19,21.91-0.83
					c0.85-6.84,0.37-11.76-1.47-14.76c-1.83-2.99-5.93-4.49-12.29-4.49c-4.77,0-9.81,0.49-15.13,1.47
					c-5.32,0.98-9.69,2.26-13.11,3.85L1276.77,1171.56z M1307.02,1239.77c4.77,0,8.55-1.07,11.37-3.21
					c2.81-2.14,4.89-4.43,6.23-6.88v-11.92c-3.79-0.37-7.43-0.43-10.91-0.18c-3.48,0.25-6.57,0.8-9.26,1.65
					c-2.69,0.86-4.83,2.08-6.42,3.67c-1.59,1.59-2.38,3.61-2.38,6.05c0,3.42,1.01,6.08,3.03,7.98
					C1300.7,1238.83,1303.48,1239.77,1307.02,1239.77z"/>
			</g>
		</g>
		<g>
			<path class="st1" d="M1020.3,1288.34c4.64-2.81,10.05-4.89,16.23-6.23c6.17-1.34,12.62-2.02,19.34-2.02
				c6.35,0,11.46,0.86,15.31,2.57c3.85,1.71,6.78,3.94,8.8,6.69c2.02,2.75,3.36,5.78,4.03,9.08c0.67,3.3,1.01,6.6,1.01,9.9
				c0,7.33-0.18,14.49-0.55,21.45c-0.37,6.97-0.55,13.57-0.55,19.8c0,4.52,0.18,8.8,0.55,12.84c0.37,4.03,1.04,7.76,2.02,11.19
				h-10.82l-3.85-12.84h-0.92c-1.1,1.71-2.48,3.39-4.13,5.04c-1.65,1.65-3.64,3.12-5.96,4.4c-2.32,1.28-5.01,2.36-8.07,3.21
				c-3.06,0.85-6.54,1.28-10.45,1.28c-3.91,0-7.55-0.61-10.91-1.83c-3.36-1.22-6.27-2.96-8.71-5.23c-2.45-2.26-4.37-4.98-5.78-8.16
				c-1.41-3.18-2.11-6.78-2.11-10.82c0-5.38,1.1-9.87,3.3-13.48c2.2-3.6,5.29-6.48,9.26-8.62c3.97-2.14,8.74-3.67,14.3-4.58
				c5.56-0.92,11.7-1.38,18.43-1.38c1.71,0,3.39,0,5.04,0c1.65,0,3.33,0.12,5.04,0.37c0.37-3.67,0.55-6.97,0.55-9.9
				c0-6.72-1.35-11.43-4.03-14.12c-2.69-2.69-7.58-4.03-14.67-4.03c-2.08,0-4.31,0.16-6.69,0.47c-2.38,0.32-4.83,0.75-7.33,1.32
				c-2.51,0.57-4.89,1.26-7.15,2.07c-2.26,0.82-4.25,1.73-5.96,2.73L1020.3,1288.34z M1046.33,1361.87c3.42,0,6.48-0.46,9.17-1.37
				c2.69-0.92,5.01-2.08,6.97-3.48c1.95-1.4,3.58-2.96,4.86-4.68c1.28-1.71,2.23-3.36,2.84-4.95v-15.22
				c-1.71-0.12-3.46-0.21-5.23-0.28c-1.77-0.06-3.52-0.09-5.23-0.09c-3.79,0-7.49,0.21-11.09,0.64c-3.61,0.43-6.78,1.22-9.53,2.38
				c-2.75,1.16-4.95,2.75-6.6,4.77c-1.65,2.02-2.48,4.56-2.48,7.61c0,4.28,1.53,7.79,4.58,10.54
				C1037.65,1360.49,1041.57,1361.87,1046.33,1361.87z"/>
			<path class="st1" d="M1171.57,1373.24v-52.26c0-9.53-1.38-16.71-4.13-21.54c-2.75-4.83-7.79-7.24-15.13-7.24
				c-6.48,0-11.83,1.74-16.04,5.23c-4.22,3.48-7.18,7.86-8.89,13.11v62.71h-14.67v-91.68h10.27l2.75,11.19h0.73
				c2.93-3.91,6.88-7.12,11.83-9.63c4.95-2.5,10.85-3.76,17.69-3.76c5.01,0,9.41,0.58,13.2,1.74c3.79,1.16,6.94,3.21,9.44,6.14
				c2.5,2.93,4.4,6.88,5.68,11.83c1.28,4.95,1.92,11.22,1.92,18.79v55.37H1171.57z"/>
			<path class="st1" d="M1211.54,1288.34c4.64-2.81,10.05-4.89,16.23-6.23c6.17-1.34,12.62-2.02,19.34-2.02
				c6.35,0,11.46,0.86,15.31,2.57c3.85,1.71,6.78,3.94,8.8,6.69c2.02,2.75,3.36,5.78,4.03,9.08c0.67,3.3,1.01,6.6,1.01,9.9
				c0,7.33-0.18,14.49-0.55,21.45c-0.37,6.97-0.55,13.57-0.55,19.8c0,4.52,0.18,8.8,0.55,12.84c0.37,4.03,1.04,7.76,2.02,11.19
				h-10.82l-3.85-12.84h-0.92c-1.1,1.71-2.48,3.39-4.13,5.04c-1.65,1.65-3.64,3.12-5.96,4.4c-2.32,1.28-5.01,2.36-8.07,3.21
				c-3.06,0.85-6.54,1.28-10.45,1.28c-3.91,0-7.55-0.61-10.91-1.83c-3.36-1.22-6.27-2.96-8.71-5.23c-2.45-2.26-4.37-4.98-5.78-8.16
				c-1.41-3.18-2.11-6.78-2.11-10.82c0-5.38,1.1-9.87,3.3-13.48c2.2-3.6,5.29-6.48,9.26-8.62c3.97-2.14,8.74-3.67,14.3-4.58
				c5.56-0.92,11.7-1.38,18.43-1.38c1.71,0,3.39,0,5.04,0c1.65,0,3.33,0.12,5.04,0.37c0.37-3.67,0.55-6.97,0.55-9.9
				c0-6.72-1.35-11.43-4.03-14.12c-2.69-2.69-7.58-4.03-14.67-4.03c-2.08,0-4.31,0.16-6.69,0.47c-2.38,0.32-4.83,0.75-7.33,1.32
				c-2.51,0.57-4.89,1.26-7.15,2.07c-2.26,0.82-4.25,1.73-5.96,2.73L1211.54,1288.34z M1237.58,1361.87c3.42,0,6.48-0.46,9.17-1.37
				c2.69-0.92,5.01-2.08,6.97-3.48c1.95-1.4,3.58-2.96,4.86-4.68c1.28-1.71,2.23-3.36,2.84-4.95v-15.22
				c-1.71-0.12-3.46-0.21-5.23-0.28c-1.77-0.06-3.52-0.09-5.23-0.09c-3.79,0-7.49,0.21-11.09,0.64c-3.61,0.43-6.78,1.22-9.53,2.38
				c-2.75,1.16-4.95,2.75-6.6,4.77c-1.65,2.02-2.48,4.56-2.48,7.61c0,4.28,1.53,7.79,4.58,10.54
				C1228.89,1360.49,1232.81,1361.87,1237.58,1361.87z"/>
			<path class="st1" d="M1347.22,1295.68c-3.67-1.22-7.15-1.83-10.45-1.83c-5.26,0-9.41,1.44-12.47,4.31
				c-3.06,2.87-4.95,6.51-5.68,10.91v64.18h-14.67v-91.68h10.27l2.75,11.19h0.73c2.32-4.15,5.1-7.36,8.34-9.63
				c3.24-2.26,7.36-3.39,12.38-3.39c3.54,0,7.52,0.61,11.92,1.83L1347.22,1295.68z"/>
			<path class="st1" d="M1433.22,1365.9c-3.67,2.93-8.28,5.26-13.84,6.97c-5.56,1.71-11.46,2.57-17.69,2.57
				c-6.97,0-13.02-1.13-18.15-3.39c-5.13-2.26-9.35-5.5-12.65-9.72c-3.3-4.22-5.75-9.26-7.33-15.13c-1.59-5.87-2.38-12.47-2.38-19.8
				c0-15.65,3.48-27.56,10.45-35.75c6.97-8.19,16.93-12.29,29.89-12.29c4.15,0,8.31,0.46,12.47,1.37c4.15,0.92,7.88,2.66,11.19,5.23
				c3.3,2.57,5.99,6.21,8.07,10.91c2.08,4.71,3.12,10.91,3.12,18.61c0,4.03-0.37,8.5-1.1,13.38h-58.86c0,5.38,0.55,10.15,1.65,14.3
				c1.1,4.16,2.81,7.67,5.13,10.54c2.32,2.87,5.35,5.07,9.08,6.6c3.73,1.53,8.28,2.29,13.66,2.29c4.15,0,8.31-0.73,12.47-2.2
				c4.15-1.47,7.27-3.18,9.35-5.13L1433.22,1365.9z M1397.1,1239.02h5.87l20.35,33.37h-13.2l-7.15-12.1l-3.85-10.63l-4.03,10.63
				l-8.25,12.1h-12.29L1397.1,1239.02z M1401.68,1292.19c-7.21,0-12.99,1.9-17.33,5.68c-4.34,3.79-6.94,10.27-7.79,19.44h45.47
				c0-9.29-1.77-15.8-5.32-19.53C1413.17,1294.06,1408.16,1292.19,1401.68,1292.19z"/>
		</g>
	</g>
	<g>
		<g>
			<polygon class="st0" points="1600.13,230.64 1607.13,338.93 1476.54,479.91 1409.6,586.2 1370.03,627.68 1471.61,466.65 
				1503.04,410.54 1549.92,352.25 			"/>
			<polygon class="st0" points="1516.55,301.65 1515.15,344.52 1463.72,462.46 1371.04,627.35 1346.77,622.83 1336.11,603.33 
				1462.77,366.31 1466.14,312.67 1542.35,198.12 1560.07,191.03 			"/>
			<path class="st0" d="M1439.93,279.24l-32.68,60.8l2.13,98.81c-12.51,21.22-36.31,44.21-49.68,65.09l37.22-63.79l-1.95-48.19
				l-2-52.61l-18.16-127.66l-43.95,113.17l21.83,96.48l7.73,46.26l-74.09,142.89l25.61-24.73l24.16,17.56l118.5-238.83l7.22-53.64
				l15.86-119.59L1439.93,279.24z"/>
			<polygon class="st0" points="1317.94,304 1294.98,306.97 1318.92,448.12 1286.34,610.49 1353.74,465.9 1345.33,416.93 
				1324.41,324.07 			"/>
			<polygon class="st0" points="1286.34,610.49 1289.94,547.91 1278.36,517.07 1282.43,487.13 1240.66,366.51 1280.92,312.41 
				1293.75,354.02 1312.01,449.37 			"/>
			<polygon class="st0" points="1286.34,610.49 1258.18,521.16 1235.94,474.68 1188.97,418.01 1229.32,359.71 1277.56,488.84 
				1275.3,518.32 1289.94,547.91 			"/>
			<polygon class="st0" points="1185.97,429.11 1150.45,491.27 1230.74,523.21 1286.34,610.49 1254.77,522.78 			"/>
			<polygon class="st0" points="1244.36,550.64 1147.96,496.71 1104.9,547.98 1197.2,566.31 1223.93,600.39 1192.21,573.34 
				1100.14,567.48 1070.45,599.02 1148.97,603.31 1060.02,611.27 1021.26,656.63 1079.29,634.13 1149.6,630.28 1187.54,627.97 
				1207.72,616.17 1236.29,610.94 1270.91,595.08 			"/>
			<polygon class="st0" points="1038.07,712.19 1012.62,737.32 892.5,814.39 928.56,771.42 937.18,763.48 			"/>
			<polygon class="st0" points="951.39,781.86 895.82,815.95 903.1,817.93 957.37,794.31 973.3,788.72 			"/>
			<polygon class="st2" points="1228.93,627.36 1212.8,642.22 1169.35,656.36 1141.48,659.34 1146.24,634.96 1188.4,631.53 
				1210.85,619.16 1232.84,615.72 			"/>
			<polygon class="st1" points="816.9,793.74 833.11,813.63 834.25,833.59 996.41,884.55 1024.67,882.59 995.55,854.98 			"/>
			<polygon class="st2" points="827.53,828.82 774.49,811.36 674.58,786.87 649.11,765.45 520.32,725.79 508.36,717 601.8,740.72 
				825.3,821.16 			"/>
			<polygon class="st0" points="524.67,748.85 417.69,660.84 448.32,671.77 516.59,727.06 647.39,768.85 661.02,779.97 
				619.39,776.51 			"/>
			<polygon class="st0" points="380.26,633.51 416.78,663.88 323.09,608.38 307.22,592.04 			"/>
			<polygon class="st0" points="427.89,673.63 316.25,624.15 262.25,581.65 262.01,569.18 296.11,590 321.16,611.17 			"/>
			<polygon class="st0" points="433.49,680.46 363.86,648.74 313.44,626.26 276.54,604.1 304.22,629.94 359.42,655.39 
				368.86,664.83 453.91,696.55 			"/>
			<polygon class="st0" points="483.34,724.06 409.47,707.68 338.7,675.28 311.71,652.16 360.7,666.88 382.47,676.85 459.03,703.63 
							"/>
			<polygon class="st0" points="522.24,751.29 470.48,743.48 358.9,713.59 324.42,687.53 331.45,680.95 394.5,705.64 431.24,717.65 
				486.74,729.89 			"/>
			<polygon class="st0" points="606.78,776.33 510.69,766.93 436.54,757.01 356.41,730.6 360.95,721.76 425.13,735.56 
				475.93,749.83 541.47,757.51 			"/>
			<polygon class="st0" points="665.52,791.02 441.25,796.1 399.51,768 392.03,753.49 439.2,764.81 660.76,783.54 			"/>
			<polygon class="st0" points="694.1,801.22 519.49,812.17 468.24,815.37 430.14,805.63 517.9,798.11 687.52,794.19 707.93,798.72 
							"/>
			<polygon class="st0" points="761.9,810.26 537.41,829.85 525.17,834.85 505.44,825.33 540.36,814.66 722.67,803.7 			"/>
			<polygon class="st0" points="780.28,822.04 635.61,840.92 583.69,860.67 563.05,855.24 588.89,835.27 677.78,824.58 
				774.83,815.7 			"/>
			<polygon class="st0" points="797.74,830.2 633.12,865.64 596.39,872.91 595.48,861.58 653.98,845 791.62,824.99 			"/>
			<polygon class="st0" points="814.98,837.45 661.47,878.78 628.37,885.15 634.03,869.27 768.72,841.32 812.71,832.24 			"/>
			<polygon class="st0" points="837.47,844.16 688.92,903.72 659.9,903.28 648.78,897.38 662.84,884.23 821.56,840.62 
				836.22,838.39 			"/>
			<polygon class="st0" points="855.77,847.06 770.56,898.7 726.57,915.49 680.31,923.22 681.67,909.39 716.13,896.9 787.78,867.4 
				844.49,847.42 			"/>
			<polygon class="st0" points="879.57,855.12 764.56,918.32 727.61,936.25 711.28,940.34 690.42,934 747.78,912.89 782.92,895.41 
				863.49,849.38 			"/>
			<polygon class="st0" points="925.3,866.49 853.63,890.05 780.78,931.8 733.84,940.66 890.84,856.78 			"/>
			<polygon class="st1" points="834.28,906.83 812.52,935.42 768.28,967.21 679.47,1032.79 690.52,1009.05 757.2,941.56 
				784.86,936.56 			"/>
			<polygon class="st2" points="499.67,1133.28 409.88,1167.33 345.11,1197.49 412.38,1181.16 			"/>
			<polygon class="st2" points="499.3,1166.59 407.02,1213.79 275.28,1234.26 314.96,1215.65 425.31,1182.75 			"/>
			<polygon class="st2" points="332.14,1203.74 192.88,1190.31 215.67,1215.4 277.25,1222.69 			"/>
			<polygon class="st0" points="770.72,998.78 623.03,1129.38 544.35,1173.17 549.79,1152.54 623.25,1114.86 772.79,981.63 			"/>
			<polygon class="st0" points="780.59,1038.83 761.78,1067.64 639.36,1144.56 734.12,1072.64 			"/>
			<path class="st0" d="M619.68,1139.55c20.69-18.9,41.38-37.79,62.06-56.69l83.19-71.92c1.22,5.12,2.44,10.24,3.67,15.36
				l11.38,2.53L631.2,1146.61l-77.02,23.59L619.68,1139.55z"/>
			<polygon class="st1" points="1307.98,593.77 1311.06,620.69 1325.81,656.51 1366.79,634.93 1345.51,629.61 1332.82,607.53 			"/>
			<polygon class="st1" points="1290.77,688.82 1263.67,643.16 1234.41,630.24 1237.13,618 1264.79,605.29 1282.37,612.62 
				1294.66,685.42 			"/>
			<polygon class="st1" points="1282.29,694.4 1259.15,671.28 1175.24,667.23 1148.26,682.66 1190.15,684.13 1270.73,705.97 			"/>
			<polygon class="st1" points="1266.16,707.51 1188.92,687.19 1156.08,686.51 1158.47,699.05 1172.75,703.09 1251.91,723.21 			"/>
			<polygon class="st1" points="1150.54,714.86 1227.88,746.58 1217.91,756.79 1151.02,741.78 1105.72,735.21 1104.9,721.54 			"/>
			<polygon class="st1" points="1115.34,714.14 1106.99,718.72 1153.94,709.19 1232.64,742.49 1247.83,726.16 1156.58,702.55 			"/>
			<polygon class="st1" points="1213.83,759.74 1201.14,770.63 1098.53,760.11 1078.21,748.57 1103.39,738.24 			"/>
			<polygon class="st1" points="1197.74,776.3 1151.47,768.61 1096.37,764.09 1096.37,764.09 1078.68,770.45 1091.84,772.94 
				1193.88,784.01 			"/>
			<polygon class="st1" points="1190.69,787.47 1146.93,795.65 1028.58,797.45 1054.87,779.3 1081.18,776.57 			"/>
			<polygon class="st1" points="1162.59,801.34 1031.98,803.35 1020.64,804.26 1012.26,816.96 1015.44,825.81 1070.31,817.85 			"/>
			<polygon class="st1" points="1012.04,833.23 993.62,846.44 935.67,828.31 970.24,824.6 			"/>
			<polygon class="st0" points="779.03,971.86 613.57,1114.84 513.13,1160.23 678.82,1041.86 756.59,981.86 			"/>
			<polygon class="st0" points="426.75,1215.6 545.32,1150.24 532.34,1179.08 			"/>
			<polygon class="st1" points="1270.62,816.52 1260.69,809.29 1228.51,799.02 1178.74,803.02 1014.86,835.6 1000.76,846.14 
				1160.52,854.12 1185.07,850.8 1215.59,860.07 1240.8,853.76 			"/>
			<polygon class="st3" points="1180.34,865.88 1166.16,876.53 1161.79,887.37 1167.63,893 1168.26,902.6 1145.52,882.58 
				1163.03,860.47 1182.21,855.66 1202.44,858.57 1214.33,863.58 1204.95,868.58 			"/>
			<polygon class="st0" points="1274.64,820.17 1280.62,860.71 1278.97,887.92 1267.7,910.48 1247.14,929.73 1214.58,953.05 
				1244.48,913.48 1232.19,881.3 1221.9,868.36 1245.12,859.73 			"/>
			<polygon class="st1" points="998.97,850.01 1068.57,911.6 1113.21,923.47 1173.27,915.52 1136.55,882.17 1157.6,860.05 
				1071.89,864.68 			"/>
			<path class="st2" d="M856.8,894.22l-15.92,15.27l-63.78,70.95l-1.87,21.64l168.48,9.34c43.87-17.97,87.73-35.93,131.6-53.9
				l39.29-24.92l-44.63-12.09l-39.99-32.99l-34.17,4.33l-66.2-19.38L856.8,894.22z"/>
			<polygon class="st0" points="933.49,1020.26 769.92,1009.05 771.92,1022.65 795.81,1027.95 768.62,1065.78 			"/>
			<polygon class="st2" points="930.64,827.01 971.95,821.07 1012.49,830.01 1007.98,817.87 1009.49,812.18 992,806.68 
				955.77,809.43 918.14,823 			"/>
			<polygon class="st2" points="1060.33,771.84 1015.18,775.35 980.41,790.89 946.08,802.21 908.15,819.52 916.14,821.01 
				954.34,805.4 991.88,800.75 1012.81,809.05 1024.62,796.66 1051.02,777.71 1077.65,772.94 			"/>
			<polygon class="st2" points="1017.75,739.66 1042.11,711.66 1101.18,717.91 			"/>
			<polygon class="st1" points="431.91,1176.3 496.05,1163.17 670.37,1038.5 681.91,1016.2 507.67,1134.89 			"/>
			<polygon class="st2" points="1072.28,755.62 1009.53,749.46 996.32,752.59 960.95,774.55 954.24,779.42 978.43,786.86 
				1013.71,770.17 1078.16,767.46 1093.82,760.88 			"/>
			<polygon class="st0" points="1097.72,688.66 1011.79,697.99 1021.43,680.53 			"/>
			<polygon class="st0" points="1021.43,675.94 1024.65,659.4 1080.29,640.46 1144.03,633 1136.49,660.87 1049.48,676.81 			"/>
			<polygon class="st0" points="948.07,751.03 1010.33,702.23 1036.59,708.95 938.33,760.18 			"/>
			<polyline class="st1" points="1258.46,664.7 1178.53,664.29 1174.56,660.65 1215.14,645.67 1227.67,632.17 1265.04,652.45 
				1288.86,693.72 			"/>
			<polygon class="st1" points="1306.05,620.78 1303.95,598.86 1286.41,613.63 1298.79,681.34 1324.31,659.11 			"/>
		</g>
		<polygon class="st0" points="1205.79,875.47 1215.17,870.46 1219.98,889.64 1231.03,894.85 1232.49,900.69 1207.68,914.88 
			1187.66,916.35 1167,886.95 1181.18,872.77 		"/>
		<polygon class="st2" points="1014.1,743.75 1070.83,750.15 1100.1,735.54 1100.35,722.11 		"/>
		<polygon class="st2" points="1150.4,685.79 1114.45,689.3 1017.11,701.02 1106.73,712.14 1154.15,699.86 		"/>
		<polygon class="st2" points="1050.65,679.28 1168.48,660.99 1174.89,663.63 1141.28,684.25 1101.51,686.46 		"/>
		<polygon class="st2" points="828.27,816.27 813.31,796.54 695.02,762.18 636.41,749.04 679.17,763.73 		"/>
	</g>
</g>
</svg>
`;
export default logo;
